<template>
  <el-card shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Tabela de Preços</h4>
        <el-col :md="18">
          <el-row :gutter="20" type="flex" justify="end">
            <el-autocomplete value-key="name" :value="CategoryName" :fetch-suggestions="fetchProductCategories"
              placeholder="Selecione uma categoria" @select="selectCategory">
              <template #prepend>Categoria de Produto:</template>
            </el-autocomplete>
            <el-button :disabled="!CompiledProducts?.length" type="primary" size="medium"
              @click="createPDF">Imprimir</el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table stripe :cell-style="() => 'text-align:center;'" :data="CompiledProducts" style="width: 100%; z-index: 0"
      :row-class-name="(scope) => (scope.row.main_product ? 'header-color' : '')
        ">
      <el-table-column prop="name" label="nome">
        <template #default="s">
          <h4 v-if="s.row.main_product">{{ s?.row?.name }}</h4>
          <small v-else>{{ s?.row?.name }}</small>
        </template>
      </el-table-column>
      <!--

        <el-table-column label="preço min. macho">
        <template #default="scope">
          <base-input
            type="money"
            label="R$"
            v-if="!scope.row.main_product"
            v-model="scope.row.price.male_min"
            v-on:keyup.enter="saveProductMountedPrice(scope.row)"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="preço ref. macho">
        <template #default="scope">
          <base-input
            type="money"
            label="R$"
            v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedPrice(scope.row)"
            v-model="scope.row.price.male_std"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="preço min. fêmea">
        <template #default="scope">
          <base-input
            type="money"
            label="R$"
            v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedPrice(scope.row)"
            v-model="scope.row.price.female_min"
          ></base-input>
        </template>
      </el-table-column>

      -->
      <el-table-column label="preço referência">
        <template #default="scope">
          <base-input type="money" label="R$" v-if="!scope.row.main_product"
            v-on:keyup.enter="saveProductMountedPrice(scope.row)" v-model="scope.row.price.female_std"></base-input>
        </template>
      </el-table-column>
      <el-table-column label="praça">
        <template #default="{ row: productMounted }">
          <el-switch v-if="!productMounted.main_product" v-model="productMounted.is_normal_sell_available"
            @change="saveProductMountedAvailability({ ...productMounted })"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="licitação">
        <template #default="{ row: productMounted }">
          <el-switch v-if="!productMounted.main_product" v-model="productMounted.is_public_sell_available"
            @change="saveProductMountedAvailability({ ...productMounted })"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="grupo">
        <template #default="{ row: productMounted }">
          <div v-if="!productMounted.main_product">
            <el-select v-model="productMounted.group" @change="(v) => saveProductMountedGroup({ ...productMounted, group: v })
              ">
              <el-option v-for="group in ProductGroups" :key="productMounted.id + group" :value="group"
                :label="group"></el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import BaseInput from "../components/BaseInput.vue";
import { generatePDF } from "../services/reports";
import { numberFormatter, dateFormatter } from "../utils/formatters";
import { notifyError, notifySuccess } from "../utils/notifiers";
import ProductService from "../services/products";
export default {
  name: "PricesTable",
  components: { BaseInput },
  data: () => ({
    hasError: false,
    isLoading: false,
    categories: null,
    category: null,
    compositionList: null,
  }),
  computed: {
    ShowProducts() {
      return (
        this?.category?.products.map((p) => ({ ...p, price: p.price || {} })) ||
        []
      );
    },
    ProductGroups() {
      const CategoryProductGroups = {
        "BOVINOS": [
          "CARNE BOVINA COM OSSO",
          "CARNE BOVINA SEM OSSO",
          "MIÚDOS BOVINOS",
          "CARNE BOVINA PROCESSADA",
          "CARNE BOVINA INDUSTRIALIZADA",
        ],
        "SUINOS": [
          "CARNE SUINA COM OSSO",
          "CARNE SUINA SEM OSSO"
        ],
        "AVES": [
          "CORTES DE FRANGO SEM OSSO",
          "CORTES DE FRANGO COM OSSO"
        ],
        "CAPRINO": [
          "CARNEIRO SEM OSSO",
          "CARNEIRO COM OSSO"
        ],
        "DEFUMADOS": [
          "INDUSTRIALIZADOS"
        ],
        "FRIOS": [
          "INDUSTRIALIZADOS"
        ],
      }
      return CategoryProductGroups[this.category?.name];
    },
    CompiledProducts() {
      return this.ShowProducts.map((p) =>
        [
          {
            ...p,
            main_product: true,
          },
          p?.mounted
            .map((m) => ({
              ...p,
              is_normal_sell_available: m.is_normal_sell_available,
              is_public_sell_available: m.is_public_sell_available,
              price: { ...m.price },
              id: m.id,
              name: `${p.name} ${m.cutting ?? ""} ${m.composition ?? ""} ${m.storage ?? ""
                }`,
              group: m.group,
            }))
            ?.flat(),
        ]?.flat()
      )?.flat();
    },
    CategoryName() {
      return this.category?.name || "";
    },
  },
  methods: {
    saveProductMountedPrice(p) {
      this.save({
        uid: p.uid,
        price: {
          ...p.price,
          product_mounted_id: p.id,
        },
      });
    },
    saveProductMountedGroup(productMounted) {
      this.save({
        uid: productMounted.uid,
        group: {
          group: productMounted.group,
          product_mounted_id: productMounted.id,
        },
      });
    },
    saveProductMountedAvailability(productMounted) {
      this.save({
        uid: productMounted.uid,
        availability: {
          product_mounted_id: productMounted.id,
          is_normal_sell_available: productMounted.is_normal_sell_available,
          is_public_sell_available: productMounted.is_public_sell_available,
        },
      });
    },
    reloadProducts() {
      const self = this;
      this.fetchProductCategories("", (categories) => {
        if (!categories?.length) return;
        self.selectCategory(
          categories.find(({ uid }) => self.category.uid === uid)
        );
      });
    },
    fetchProductCategories(query, callback) {
      fetch(`${this.$store.state.apiUrl}categories`, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => callback(json))
        .catch(() => callback([]));
    },
    selectCategory(c) {
      this.category = c;
    },
    generateTableHeaders() {
      return [["produto", "valor"]];
    },
    generatePDFContent() {
      const allProducts = this.ShowProducts?.map((p) =>
        p.mounted?.map((m) => ({ ...p, ...m }))
      )
        .flat()
        .filter(({ is_normal_sell_available }) => !!is_normal_sell_available);

      const compositionList = new Map();

      allProducts.forEach((product) => {
        const mappedProduct = [
          `${product.name} ${product.cutting ?? ""} ${product.storage ?? ""}`,
          numberFormatter.format(Number(product.price?.female_std) || 0),
        ];
        const compositionKey = product.group || "";
        const currentProducts = compositionList.get(compositionKey);

        compositionList.set(
          compositionKey,
          compositionList.has(compositionKey)
            ? [...currentProducts, mappedProduct]
            : [mappedProduct]
        );
      });

      this.compositionList = compositionList;

      return Array.from(compositionList.keys()).map((key) => {
        return compositionList.get(key);
      });
    },
    createPDF() {
      const headers = [];
      const content = this.generatePDFContent();

      const headerMessage = `Tabela de Preços`;
      const tableMessages = Array.from(this.compositionList.keys()).map(
        (key) => `${key || ""}`
      );

      generatePDF({
        fileName: `Tabela de Preços - ${dateFormatter
          .format(new Date())
          .replace(/\//g, "-")}.pdf`,
        headerMessage,
        headers,
        content,
        isMultipleTables: true,
        addTableSummary: true,
        addTableTitle: true,
        defaultFontSize: 8,
        tableMessages,
      });
    },
    async save(p) {
      const { product } = await ProductService(p.uid).update(p);

      if (product) {
        notifySuccess("Disponibilidade do produto atualizado com sucesso");
        this.reloadProducts();
      } else notifyError("Ocorreu um erro");
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}

.el-button {
  margin-left: 10px;
}

.el-autocomplete {
  margin-top: 0px !important;
  padding-top: 0px !important;
  display: block !important;
}

.header-color {
  background-color: #c0c4cc !important;
}
</style>
